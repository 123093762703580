import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const usePointBoardStore = defineStore('pointboardStore', {
  state: () => ({
    isAdminLayoutFullWidth: localStorage.getItem('layout-full-width'),
    fullPagePreloader: false,
    verticals: [],
    platforms: [],
    employees: [],
    hasNewVersion: false,
    isImpersonated: false,
    meta: {},
    verticalsLoading: true,
    homeImprovementProducts: [
      {value: 'Bathroom Remodel', label: 'Bathroom Remodel'},
      {value: 'Bath to Shower', label: 'Bath to Shower'},
      {value: 'Flooring', label: 'Flooring'},
      {value: 'Gutter', label: 'Gutter'},
      {value: 'Home Security', label: 'Home Security'},
      {value: 'Home Warranty', label: 'Home Warranty'},
      {value: 'HVAC', label: 'HVAC'},
      {value: 'Hot Tub / Spa', label: 'Hot Tub / Spa'},
      {value: 'Kitchen Remodel', label: 'Kitchen Remodel'},
      {value: 'Personal Loan', label: 'Personal Loan'},
      {value: 'Plumbing', label: 'Plumbing'},
      {value: 'Roofing', label: 'Roofing'},
      {value: 'Solar', label: 'Solar'},
      {value: 'Siding', label: 'Siding'},
      {value: 'Sprinklers', label: 'Sprinklers'},
      {value: 'Painting', label: 'Painting'},
      {value: 'Walk-in Tubs', label: 'Walk-in Tubs'},
      {value: 'Walk-in Shower', label: 'Walk-in Shower'},
      {value: 'Windows', label: 'Windows'},
    ],
    leadGenerationVerticalOptions: [
      {value: 'Home Services', label: 'Home Services'},
      {value: 'Dating', label: 'Dating'},
      {value: 'Home Insurance', label: 'Home Insurance'},
      {value: 'Auto Insurance', label: 'Auto Insurance'},
      {value: 'Health Insurance', label: 'Health Insurance'},
      {value: 'Life Insurance', label: 'Life Insurance'},
      {value: 'Ecom', label: 'Ecom'},
      {value: 'Sweeps', label: 'Sweeps'},
      {value: 'Refinance', label: 'Refinance'},
      {value: 'Loans', label: 'Loans'},
      {value: 'Nutra', label: 'Nutra'},
    ],
    regions: [
      {value: 'mena', title: 'Mena'},
      {value: 'asia', title: 'Asia'},
      {value: 'north_america', title: 'North America'},
      {value: 'ukraine', title: 'Ukraine'},
      {value: 'usa', title: 'USA'},
      {value: 'estonia', title: 'Estonia'},
    ],
    languagesOptions: [
      {label: 'English', value: 'english'},
      {label: 'Українська', value: 'ukrainian'},
      {label: 'Русский', value: 'russian'},
    ],
  }),
  getters: {
    containerWidthClass: state => {
      return state.isAdminLayoutFullWidth ? 'container-fluid' : 'container'
    },
    verticalsOptions: state => state.verticals.map(({name}) => ({value: name, label: name})),
  },
  actions: {
    pingBackend: async function () {
      try {
        const {data} = await axios.get('/cabinet/ping')

        this.hasNewVersion = this.meta.app_version && (this.hasNewVersion || data.app_version !== this.meta.app_version)
        this.meta = data
      } catch (e) {
        console.log(e)
      }
    },
    setFullPagePreloader(value) {
      this.fullPagePreloader = value
    },
    getVerticals: async function (platform = '') {
      this.verticalsLoading = true
      platform = ['meta', 'tiktok'].includes(platform) ? 'meta_and_tiktok' : platform

      try {
        const params = platform ? {platform} : {}

        const {data} = await axios.get('ad-verticals', {params})

        this.verticals = data
      } catch (e) {
        console.error(e)
      } finally {
        this.verticalsLoading = false
      }
    },
    getPlatforms: async function () {
      try {
        const {data} = await axios.get('ad-platforms')

        this.platforms = data

        return data
      } catch (e) {
        console.error(e)
      }
    },
    getEmployees: async function (filters = {}) {
      try {
        const {data} = await axios.get('employees', {params: filters})

        this.employees = data

        return data
      } catch (e) {
        console.error(e)
      }
    },

    getSlackThreadLink: async function (payload) {
      const {data} = await axios.post('slack/get-thread-link', payload)

      return data
    },

    sendAnalytics: async function (payload) {
      const {data} = await axios.post('analytics', payload)

      return data
    },

    toggleIsImpersonated: async function (value) {
      this.isImpersonated = value
    },
  },
})
