import {isProduction} from '@/plugins/helpers'

export const accessRules = {
  hasAccessToDashboard: auth => !auth.isPoint2WebEmployee && auth.canSeeDashboard,
  hasAccessToUserSettings: auth => !auth.isCoreTeam && auth.isAgencyClientAdmin,
  hasAccessToMyRequests: auth => auth.userHasCompany,
  hasAccessToCreativeModerationRequest(auth) {
    return auth.isComplianceManager || auth.isAgency || auth.isDeveloper
  },
  hasAccessToClientPreModerationList: auth => auth.isComplianceManager || auth.isAgency || auth.isDeveloper,
  hasAccessToRequestsPage: auth => auth.hasAccessToRequestsPage,
  hasAccessToCreativeModerationList(auth) {
    return auth.canWorkWithCreatives && !auth.isAgency && !auth.isComplianceManager
  },
  hasAccessToCreativeModerationProfile(auth) {
    return this.hasAccessToCreativeModerationList(auth) || this.hasAccessToCreativeModerationRequest(auth)
  },

  hasAccessToPaymentsDailyBalance: auth => auth.isAgencyClient,
  hasAccessToFinancePage: auth => auth.canUseInvoices,
  hasAccessToInvoices: auth => auth.isFinance,

  hasAccessToClientOnboarding: auth => auth.canWorkWithOnboardings,
  hasAccessToOnboardingDocument: auth => auth.isLegalOrFinance,
  hasAccessToCompaniesPage: auth => auth.hasAccessToCompaniesPage,
  hasAccessToEmployeeList: auth => auth.canAddUserToPointboard,

  hasAccessToFacebookPages: auth => auth.isTechDepartment || auth.isAgencyAssistance,
  hasAccessToAssetsPage: auth => auth.hasAccessToAssetsPage,
  hasAccessToTracker: auth => auth.isDeveloperOrInternalCompany && !auth.isGoogleUser,
  hasAccessToLandings: auth => auth.isDeveloper || auth.isMediaBuyer,
  hasAccessToAdAlerts: auth => auth.isDeveloper || auth.isMediaBuyer,

  hasAccessToLandingTask: auth => auth.canCreateNewLandingTask,
  hasAccessToLandingCopyTask: auth => auth.canCreateCopyLandingTask,
  hasAccessToABTestTask: auth => auth.canCreateABtestTask,
  hasAccessToCreativesTask: auth => auth.canCreateCreativeTask,
  hasAccessToShortTask: auth => auth.canCreateShortTask,
  hasAccessToSMMTask: auth => auth.canCreateSMMTask,
  hasAccessToHandsOnTask: auth => auth.isAgency,
  hasAccessToAgencyGeneralTask: auth => auth.canCreateAgencyGeneralTask,

  hasAccessToOtherTask: () => !isProduction(),
  hasAccessToPaymentRequestTask: auth => auth.isPoint2WebEmployee,
  hasAccessToTasks: auth => auth.isPoint2WebEmployee,
  hasAccessToReports: auth => auth.isPoint2WebEmployee,
  hasAccessToCRDomain: auth => auth.canUseDomains && auth.fromSFCompany,
  hasAccessToDomains: auth => auth.canUseDomains,
  hasAccessToFacebookProfiles: auth => auth.canSeeFacebookPages,
  hasAccessToSFLinks: auth => auth.fromSFCompany || auth.isDeveloper,

  hasAccessToTools: auth => auth.isPoint2WebEmployee,
  hasAccessToClickupPage: auth => auth.isPoint2WebEmployee,
  hasAccessToOtherRequestList: () => !isProduction(),
  canCreateCreativeModerationRequest(auth) {
    return auth.isMediaPlanner || auth.isAgencyClient || auth.isCSM || auth.isComplianceManager
  },
}
