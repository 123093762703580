<script setup>
import {useNotification} from '@kyvg/vue3-notification'
import {ref, defineEmits, onMounted, defineExpose, defineProps, computed} from 'vue'
import {Modal as BaseModal} from 'bootstrap'
import ApiErrors from '@/components/ui/ApiErrors.vue'
import BaseForm from '@/components/base/BaseForm.vue'
import TheSelect from '@/components/ui/selects/TheSelect.vue'
import {useAdAccountWhiteListRequestStore} from '@/views/ad-account-requests/white-lists/AdAccountWhiteListRequestStore'
import MetaDatingWhiteListForm from '@/views/ad-account-requests/white-lists/components/meta/MetaDatingWhiteListForm.vue'
import MetaGamblingWhiteListForm from '@/views/ad-account-requests/white-lists/components/meta/MetaGamblingWhiteListForm.vue'
import MetaPharmaWhiteListForm from '@/views/ad-account-requests/white-lists/components/meta/MetaPharmaWhiteListForm.vue'
import MetaCryptoWhiteListForm from '@/views/ad-account-requests/white-lists/components/meta/MetaCryptoWhiteListForm.vue'

import TheSelectAdAccounts from '@/components/ui/selects/TheSelectAdAccounts.vue'
import {storeToRefs} from 'pinia'
import {useAuthenticationStore} from '@/stores'
import {useCompanyStore} from '@/views/companies/CompanyStore'
import GoogleGamblingWhiteListForm from '@/views/ad-account-requests/white-lists/components/google/GoogleGamblingWhiteListForm.vue'
import GoogleSocialCasinoWhiteListForm from '@/views/ad-account-requests/white-lists/components/google/GoogleSocialCasinoWhiteListForm.vue'
import GoogleFinancialWhiteListForm from '@/views/ad-account-requests/white-lists/components/google/GoogleFinancialWhiteListForm.vue'
import ProcessOwnerRow from '@/views/tasks/components/ProcessOwnerRow.vue'
import GoogleHealthInsuranceWhiteListForm from '@/views/ad-account-requests/white-lists/components/google/GoogleHealthInsuranceWhiteListForm.vue'

const {createAdAccountWhiteListRequest} = useAdAccountWhiteListRequestStore()
const {authUser, isCoreTeam, isPoint2WebEmployee} = storeToRefs(useAuthenticationStore())
const {companies} = storeToRefs(useCompanyStore())

const {notify} = useNotification()

// Props & emits
const emit = defineEmits(['created', 'hidden'])
const props = defineProps({
  activeRequest: {
    type: Object,
    default: null,
  },
  id: {
    type: String,
    default: null,
  },
})

// Refs
const modalId = ref(props.id ?? 'white-list-modal')
const modal = ref()
const loading = ref(false)
const error = ref(null)
const activeWhiteListRequest = ref()
const form = ref()
const metaPharmaForm = ref()
const metaCryptoForm = ref()
const metaGamblingForm = ref()
const metaDatingForm = ref()
const googleGamblingForm = ref()
const googleSocialCasinoForm = ref()
const googleFinancialForm = ref()
const requestFormData = new FormData()

const defaultMainFormData = {
  platform: '',
  company_hash: authUser.value.company?.hash ?? null,
  vertical: '',
  ad_account_id: '',
}

let mainFormData = ref(structuredClone(defaultMainFormData))
let formDataFields = ref({})

// Computed
const companiesOptions = computed(() => companies.value.map(company => ({value: company.hash, label: company.title})))
const verticalOptions = computed(() => {
  if (mainFormData.value.platform === 'facebook') {
    return [
      {value: 'crypto', label: 'Crypto'},
      {value: 'gambling', label: 'Gambling'},
      {value: 'dating', label: 'Dating'},
      {value: 'pharma', label: 'Pharma'},
    ]
  } else {
    return [
      {value: 'gambling', label: 'Online, real-money gambling'},
      {value: 'social_casino', label: 'Social casino'},
      {value: 'financial_products', label: 'Financial products and services'},
      {value: 'health_insurance', label: 'Health insurance (US only)'},
    ]
  }
})
const isSubmitButtonDisabled = computed(() => {
  const mainFieldsFilled =
    mainFormData.value.platform &&
    mainFormData.value.vertical &&
    mainFormData.value.company_hash &&
    mainFormData.value.ad_account_id

  return !mainFieldsFilled
})
let platfromOptions = [
  {value: 'facebook', label: 'Meta'},
  {value: 'google', label: 'Google'},
]

// Methods
onMounted(() => {
  let modalElement = document.getElementById(modalId.value)
  modal.value = new BaseModal(modalElement)

  modalElement.addEventListener('hidden.bs.modal', () => {
    emit('hidden')
  })

  init()
  openModal()
})

const init = () => {
  props.activeRequest !== null ? fillFormFields(props.activeRequest) : resetForms()
}

const onPlatformChange = value => {
  resetForms()

  mainFormData.value.platform = value
}

const fillFormFields = request => {
  activeWhiteListRequest.value = structuredClone(request)
  mainFormData.value = structuredClone(request)
  mainFormData.value.company_hash = request.company?.hash
}

let resetForms = () => {
  activeWhiteListRequest.value = ''
  mainFormData.value = structuredClone(defaultMainFormData)

  metaPharmaForm.value?.resetForm()
  metaCryptoForm.value?.resetForm()
  metaGamblingForm.value?.resetForm()
  metaDatingForm.value?.resetForm()
}

const openModal = () => {
  error.value = null
  modal.value.show()
}

const closeModal = () => {
  form.value.reset()

  modal.value.hide()
}

defineExpose({openModal, closeModal})

const handleCreate = async () => {
  loading.value = true

  let payload = getRequestPayload()

  try {
    await createAdAccountWhiteListRequest(requestFormData, payload)
  } catch ({data}) {
    return (error.value = data)
  } finally {
    loading.value = false
  }

  emit('created')
  closeModal()
  notify({type: 'success', text: 'Whitelist created'})
}

const getRequestFile = fileFormData => {
  for (const pair of fileFormData.entries()) {
    if (!requestFormData.get(pair[0])) {
      requestFormData.append(pair[0], pair[1])
    }
  }
}

const deleteRequestFile = file_name => {
  requestFormData.delete(`fields[${file_name}]`)
}

const getRequestPayload = () => {
  let payload
  let cloned = structuredClone(mainFormData.value)
  payload = Object.assign(cloned, formDataFields.value)

  if (mainFormData.value.platform === 'facebook') {
    payload.ad_account_id = `act_${payload.ad_account_id}`
  }

  return payload
}
const setFormDataFields = updatedFields => {
  formDataFields.value = updatedFields
}
</script>

<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <template v-if="!!activeWhiteListRequest"> Whitelist #{{ mainFormData?.hash }}</template>
            <template v-else>Whitelist Request</template>
          </h5>
          <button type="button" class="btn-close" aria-label="Close" @click="closeModal" />
        </div>
        <base-form @on-submitted="handleCreate" ref="form">
          <div class="modal-body">
            <div class="form-group">
              <label for="wl-platform">Platform *</label>
              <div class="form-group">
                <the-select
                  :options="platfromOptions"
                  v-model="mainFormData.platform"
                  id="wl-platform"
                  @change="onPlatformChange"
                  :disabled="!!activeWhiteListRequest"
                  :allow_clear="false"
                  :can_deselect="false"
                  required
                ></the-select>
              </div>
            </div>
            <template v-if="mainFormData.platform">
              <div class="form-group">
                <label for="wl-vertical">Vertical *</label>
                <div class="form-group">
                  <the-select
                    :options="verticalOptions"
                    v-model="mainFormData.vertical"
                    id="wl-vertical"
                    :disabled="!!activeWhiteListRequest"
                    :allow_clear="false"
                    :can_deselect="false"
                    required
                  ></the-select>
                </div>
              </div>
            </template>
            <template v-if="mainFormData.vertical">
              <div class="form-group" v-if="isCoreTeam">
                <label for="wl-company">Company *</label>
                <div class="form-group">
                  <the-select
                    v-model="mainFormData.company_hash"
                    :options="companiesOptions"
                    :disabled="!!activeWhiteListRequest"
                    placeholder="Select a Company"
                    id="wl-company"
                    :allow_clear="false"
                    :can_deselect="false"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <template v-if="!!activeWhiteListRequest && mainFormData.ad_account.title">
                  <label>Ad account *</label>
                  <input
                    v-model="mainFormData.ad_account.title"
                    placeholder="Ad account"
                    class="form-control"
                    readonly
                  />
                </template>
                <template v-else>
                  <label for="wl-ad-account-id">Ad account *</label>
                  <div class="input-group">
                    <the-select-ad-accounts
                      class="col"
                      id="wl-ad-account-id"
                      v-model="mainFormData.ad_account_id"
                      value-to-return="external_id"
                      :payload="{
                        company_hash: mainFormData.company_hash,
                        platform: mainFormData.platform === 'facebook' ? 'meta' : mainFormData.platform,
                      }"
                      :disabled="!!activeWhiteListRequest || mainFormData.company_hash === null"
                      required
                    ></the-select-ad-accounts>
                  </div>
                </template>
              </div>
            </template>

            <template v-if="mainFormData.platform === 'facebook'">
              <meta-dating-white-list-form
                ref="metaDatingForm"
                v-if="mainFormData.vertical === 'dating'"
                :active-request-fields="activeWhiteListRequest?.whitelist_dating_request"
                @changed="setFormDataFields"
                :disabled="!!activeWhiteListRequest"
              ></meta-dating-white-list-form>
              <meta-gambling-white-list-form
                ref="metaGamblingForm"
                v-if="mainFormData.vertical === 'gambling'"
                :active-request-fields="activeWhiteListRequest?.whitelist_gambling_request"
                @changed="setFormDataFields"
                @file-updated="getRequestFile"
                @delete-file="deleteRequestFile"
                :disabled="!!activeWhiteListRequest"
              ></meta-gambling-white-list-form>
              <meta-crypto-white-list-form
                ref="metaCryptoForm"
                v-if="mainFormData.vertical === 'crypto'"
                :active-request-fields="activeWhiteListRequest?.whitelist_crypto_request"
                @changed="setFormDataFields"
                @file-updated="getRequestFile"
                @delete-file="deleteRequestFile"
                :disabled="!!activeWhiteListRequest"
              ></meta-crypto-white-list-form>
              <meta-pharma-white-list-form
                ref="metaPharmaForm"
                v-if="mainFormData.vertical === 'pharma'"
                :active-request-fields="activeWhiteListRequest?.whitelist_pharma_request"
                @changed="setFormDataFields"
                :disabled="!!activeWhiteListRequest"
              ></meta-pharma-white-list-form>
            </template>
            <template v-else>
              <google-gambling-white-list-form
                ref="googleGamblingForm"
                v-if="mainFormData.vertical === 'gambling'"
                :active-request-fields="activeWhiteListRequest?.whitelist_gambling_google_request"
                @changed="setFormDataFields"
                @file-updated="getRequestFile"
                @delete-file="deleteRequestFile"
                :disabled="!!activeWhiteListRequest"
              >
              </google-gambling-white-list-form>
              <google-social-casino-white-list-form
                ref="googleSocialCasinoForm"
                v-if="mainFormData.vertical === 'social_casino'"
                :active-request-fields="activeWhiteListRequest?.whitelist_social_casino_request"
                @changed="setFormDataFields"
                @file-updated="getRequestFile"
                @delete-file="deleteRequestFile"
                :disabled="!!activeWhiteListRequest"
              ></google-social-casino-white-list-form>
              <google-financial-white-list-form
                ref="googleFinancialForm"
                v-if="mainFormData.vertical === 'financial_products'"
                :active-request-fields="activeWhiteListRequest?.whitelist_financial_products_request"
                @changed="setFormDataFields"
                @file-updated="getRequestFile"
                @delete-file="deleteRequestFile"
                :disabled="!!activeWhiteListRequest"
              >
              </google-financial-white-list-form>
              <google-health-insurance-white-list-form
                ref="googleHealthInsuranceForm"
                v-if="mainFormData.vertical === 'health_insurance'"
                :active-request-fields="activeWhiteListRequest?.whitelist_health_insurance_request"
                @changed="setFormDataFields"
                @file-updated="getRequestFile"
                :disabled="!!activeWhiteListRequest"
              >
              </google-health-insurance-white-list-form>
            </template>

            <div class="row" v-if="isPoint2WebEmployee">
              <div class="col d-flex justify-content-end">
                <div class="d-flex align-items-center gap-1">
                  <process-owner-row>Katia Aleksandrova</process-owner-row>
                </div>
              </div>
            </div>

            <api-errors class="mt-3" v-if="error" :error="error"></api-errors>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                v-if="!activeWhiteListRequest"
                type="submit"
                class="btn btn-primary"
                :disabled="loading || isSubmitButtonDisabled"
              >
                Submit
              </button>
            </div>
            <button type="button" class="btn btn-secondary" :disabled="loading" @click="closeModal">Close</button>
          </div>
        </base-form>
      </div>
    </div>
  </div>
</template>
