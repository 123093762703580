<script setup>
import {defineProps} from 'vue'
import AlertDanger from '@/components/ui/alerts/AlertDanger.vue'

const props = defineProps({
  error: {
    type: Object,
    default: null,
  },
})
</script>

<template>
  <alert-danger>
    <div class="title text-sm">
      <b>{{ props.error.message }}</b>
    </div>

    <div v-for="(v, k) in props.error.errors" :key="k" class="text">
      <p v-for="error in v" :key="error" class="form-group">
        {{ error }}
      </p>
    </div>
  </alert-danger>
</template>
