import {createRouter, createWebHistory} from 'vue-router'
import AdminRoutes from './admin_routes.js'
import LoginRoutes from './login_routes.js'
import {authenticationStore, pointBoardStore} from '@/main.js'
import {setPageTitle} from '@/plugins/helpers'
import {accessRules} from '@/plugins/accessControl'

const routes = [
  {
    path: '/',
    name: 'index',

    children: [AdminRoutes, LoginRoutes],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const auth = authenticationStore
  const token = localStorage.getItem('user-token')

  if (!auth.isAuthenticated && token) {
    await pointBoardStore.setFullPagePreloader(true)
    await authenticationStore.loadAuthUserInfo()
    await pointBoardStore.setFullPagePreloader(false)
  }

  if (!auth.hasTwoFA && auth.isPoint2WebEmployee && to.name !== 'two-fa') {
    return next({name: 'two-fa'})
  }

  setPageTitle(to)

  if (to.meta.access) {
    const hasAccess = to.meta.access.every(rule => {
      return accessRules[rule](auth)
    })

    if (!hasAccess) {
      return next({name: 'home'})
    }
  }

  next()
})

export default router
