<script setup>
import WhiteListModal from '@/views/ad-account-requests/white-lists/components/WhiteListModal.vue'
import ShareRequestModal from '@/views/ad-account-requests/share-permissions/components/ShareRequestModal.vue'
import AdAccountTopUpRequestModal from '@/views/ad-account-requests/top-up/AdAccountTopUpRequestModal.vue'
import AdAccountRenameRequestModal from '@/views/ad-account-requests/rename/AdAccountRenameRequestModal.vue'
import {computed, defineAsyncComponent, defineProps, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import OtherRequestModal from '@/views/ad-account-requests/other-requests/OtherRequestModal.vue'
import {isDev, isLocal, isProduction} from '@/plugins/helpers'
import {storeToRefs} from 'pinia'
import {useAuthenticationStore} from '@/stores'
import OverdraftModal from '@/views/finance/payments/components/OverdraftModal.vue'
import OnboardingComplianceCheckModal from '@/views/onboarding/components/OnboardingComplianceCheckModal.vue'
import {accessRules} from '@/plugins/accessControl'
import {authenticationStore} from '@/main'

const OnboardingClientModal = defineAsyncComponent(() =>
  import('@/views/onboarding/components/OnboardingClientModal.vue'),
)
const CompanyChangeCommissionModal = defineAsyncComponent(() =>
  import('@/views/companies/components/CompanyChangeCommissionModal.vue'),
)
const CompanyAssignManagerModal = defineAsyncComponent(() =>
  import('@/views/companies/components/CompanyAssignManagerModal.vue'),
)
const AdAccountRequestsPlatformsModal = defineAsyncComponent(() =>
  import('@/views/ad-account-requests/components/AdAccountRequestsPlatformsModal.vue'),
)

const auth = authenticationStore

const {
  isAgency,
  isAgencyClient,
  isPoint2WebEmployee,
  isMediaPlannerOrCSM,
  canCreateShortTask,
  canCreateABtestTask,
  canCreateCopyLandingTask,
  canCreateCreativeTask,
  canCreateNewLandingTask,
  canCreateOtherTask,
  canCreateSMMTask,
  canUseCreditLineList,
  isOnboardingManager,
  isCoreTeam,
  canWorkWithCreatives,
} = storeToRefs(useAuthenticationStore())

defineProps({
  isPrimary: {
    type: Boolean,
    default: false,
  },
})

const route = useRoute()
const router = useRouter()
const showWhiteListModal = ref(false)
const showTopUpRequestModal = ref(false)
const showRenameRequestModal = ref(false)
const showSharePermissionModal = ref(false)
const showOtherRequestModal = ref(false)
const showAssignManagerModal = ref(false)
const showCreditLineModal = ref(false)
const showChangeCommissionModal = ref(false)
const showOverdraftModal = ref(false)
const showOnboardingClientModal = ref(false)
const changeCommissionRequestType = ref('')
const showOnboardingComplianceModal = ref(false)
const showPlatformsModal = ref(false)

// Computed
const canApplyWhiteList = computed(() => isMediaPlannerOrCSM.value || isAgencyClient.value)
const canUseCreativeStudio = computed(
  () =>
    canCreateNewLandingTask.value ||
    canCreateCreativeTask.value ||
    canCreateSMMTask.value ||
    canCreateShortTask.value ||
    canCreateABtestTask.value ||
    canCreateCopyLandingTask.value,
)
// Methods
const openTopUpAdAccountRequestModal = () => {
  showTopUpRequestModal.value = true
}
const openRenameRequestModal = () => {
  showRenameRequestModal.value = true
}
const openShareRequestModal = () => {
  showSharePermissionModal.value = true
}
const openWhitelistRequestModal = () => {
  showWhiteListModal.value = true
}
const openAccountRequestPlatformsModal = () => {
  showPlatformsModal.value = true
}
const openOtherRequestModal = () => {
  showOtherRequestModal.value = true
}
const openAssignManagerModal = () => {
  showAssignManagerModal.value = true
}
const openCreditLineModal = () => {
  showCreditLineModal.value = true
}
const openOverdraftModal = () => {
  showOverdraftModal.value = true
}
const openChangeCommissionModal = () => {
  showChangeCommissionModal.value = true

  changeCommissionRequestType.value = 'change_commission'
}
const openHandsOnCommissionModal = () => {
  showChangeCommissionModal.value = true

  changeCommissionRequestType.value = 'hands_on_commission'
}

const onShareRequestCreated = () => {
  const resolvedRoute = router.resolve({name: 'share-permissions'})
  window.location.href = resolvedRoute.href
}
const onRenameRequestCreated = () => {
  const resolvedRoute = router.resolve({name: 'ad-account-requests.rename.list'})
  window.location.href = resolvedRoute.href
}
const onTopupRequestCreated = () => {
  const resolvedRoute = router.resolve({name: 'ad-account-requests.top-up.list'})
  window.location.href = resolvedRoute.href
}
const onOtherRequestCreated = () => {
  const resolvedRoute = router.resolve({name: 'other-requests.list'})
  window.location.href = resolvedRoute.href
}
const onWhiteRequestCreated = () => {
  const resolvedRoute = router.resolve({name: 'whitelist'})
  window.location.href = resolvedRoute.href
}
const openOnboardingClientModal = () => {
  showOnboardingClientModal.value = true
}
const openOnboardingComplianceModal = () => {
  showOnboardingComplianceModal.value = true
}
const onOnboardingClientCreated = () => {
  if (route.name === 'onboarding.list') {
    router.go(0)
  }

  router.push({name: 'onboarding'})
}
</script>

<template>
  <ad-account-top-up-request-modal
    v-if="showTopUpRequestModal"
    @hidden="showTopUpRequestModal = false"
    @created="onTopupRequestCreated"
    id="dropdown-ad-account-top-up-request-modal"
  />
  <ad-account-rename-request-modal
    v-if="showRenameRequestModal"
    @hidden="showRenameRequestModal = false"
    @created="onRenameRequestCreated"
    id="dropdown-ad-account-rename-request-modal"
  />
  <share-request-modal
    v-if="showSharePermissionModal"
    @hidden="showSharePermissionModal = false"
    id="dropdown-share-request-modal"
    @created="onShareRequestCreated"
  />
  <ad-account-requests-platforms-modal
    v-if="showPlatformsModal"
    @hidden="showPlatformsModal = false"
    id="dropdown-requests-platforms-modal"
  />
  <other-request-modal
    v-if="showOtherRequestModal"
    @created="onOtherRequestCreated"
    @hidden="showOtherRequestModal = false"
    id="dropdown-requests-other-request-modal"
  />
  <white-list-modal
    v-if="showWhiteListModal"
    ref="whiteListRequestModal"
    id="dropdown-requests-white-list-modal"
    @hidden="showWhiteListModal = false"
    @created="onWhiteRequestCreated"
  />
  <company-change-commission-modal
    v-if="showChangeCommissionModal"
    @hidden="showChangeCommissionModal = false"
    id="dropdown-requests-change-commission-modal"
    :request-type="changeCommissionRequestType"
  />
  <company-assign-manager-modal
    v-if="showAssignManagerModal"
    @hidden="showAssignManagerModal = false"
    id="dropdown-requests-assign-manager-modal"
  />
  <onboarding-client-modal
    v-if="showOnboardingClientModal"
    @hidden="showOnboardingClientModal = false"
    @created="onOnboardingClientCreated"
    @updated="onOnboardingClientCreated"
    id="dropdown-requests-onboarding-client-modal"
  />
  <onboarding-compliance-check-modal
    v-if="showOnboardingComplianceModal"
    @hidden="showOnboardingComplianceModal = false"
    @submitted="openOnboardingClientModal"
    id="dropdown-requests-compliance-onboarding-client-modal"
  />
  <overdraft-modal
    v-if="showOverdraftModal"
    @hidden="showOverdraftModal = false"
    id="dropdown-requests-modal-overdraft"
  />

  <div :class="['btn-group custom', isPoint2WebEmployee ? 'item-noborder' : '']" role="group" v-bind="$attrs">
    <div class="dropdown-toggle-wrapper">
      <button type="button" :class="['btn dropdown-toggle', isPrimary ? 'btn-primary' : 'btn-outline-primary']">
        New Request
      </button>
    </div>
    <ul v-if="!isPoint2WebEmployee" class="dropdown-menu">
      <li>
        <span @click.prevent="openAccountRequestPlatformsModal" class="dropdown-item">New Ad Account</span>
      </li>
      <li>
        <span class="dropdown-item" @click="openTopUpAdAccountRequestModal">Top-Up</span>
      </li>
      <li>
        <span class="dropdown-item" @click="openRenameRequestModal">Rename Account</span>
      </li>
      <li>
        <span class="dropdown-item" @click="openShareRequestModal">Share Access</span>
      </li>
      <li v-if="canApplyWhiteList">
        <span class="dropdown-item" @click="openWhitelistRequestModal">Whitelist</span>
      </li>
      <li v-if="canWorkWithCreatives">
        <a class="dropdown-item" :href="$router.resolve({name: 'creatives.profile'}).href">Creatives Moderation</a>
      </li>
      <li v-if="!isProduction()">
        <a class="dropdown-item" @click="openOtherRequestModal">Other</a>
      </li>
    </ul>
    <ul v-else class="dropdown-menu employee">
      <template v-if="isMediaPlannerOrCSM || !isCoreTeam">
        <li>
          <h5 class="dropdown-header fw-bold">Ad Account Requests:</h5>
        </li>
        <li>
          <span @click.prevent="openAccountRequestPlatformsModal" class="dropdown-item">New Ad Account</span>
        </li>
        <li>
          <span class="dropdown-item" @click="openTopUpAdAccountRequestModal">Top-Up</span>
        </li>
        <li>
          <span class="dropdown-item" @click="openRenameRequestModal">Rename Account</span>
        </li>
        <li>
          <span class="dropdown-item" @click="openShareRequestModal">Share Access</span>
        </li>
        <li v-if="canApplyWhiteList">
          <span class="dropdown-item" @click="openWhitelistRequestModal">Whitelist</span>
        </li>
        <li>
          <hr class="dropdown-divider" />
        </li>
      </template>
      <template
        v-if="
          accessRules.hasAccessToClientPreModerationList(auth) || accessRules.canCreateCreativeModerationRequest(auth)
        "
      >
        <li>
          <h5 class="dropdown-header fw-bold">Compliance:</h5>
        </li>
        <li v-if="accessRules.hasAccessToClientPreModerationList(auth)">
          <a class="dropdown-item" :href="$router.resolve({name: 'request.agency-general'}).href">
            <span>Client Pre-Moderation</span>
          </a>
        </li>
        <li v-if="accessRules.canCreateCreativeModerationRequest(auth)">
          <a class="dropdown-item" :href="$router.resolve({name: 'creatives.profile'}).href">
            <span>Creatives Moderation</span>
          </a>
        </li>
        <li>
          <hr class="dropdown-divider" />
        </li>
      </template>

      <li v-if="isAgency">
        <h5 class="dropdown-header fw-bold">Client Process:</h5>
      </li>
      <li v-if="(isDev() || isLocal()) && isOnboardingManager">
        <span class="dropdown-item" @click="openOnboardingComplianceModal">
          <span>New Client Onboarding</span>
        </span>
      </li>
      <li v-if="isAgency">
        <a class="dropdown-item" :href="$router.resolve({name: 'tasks.agency-hands-on'}).href">
          <span>Hands-On</span>
        </a>
      </li>
      <li v-if="isAgency || isOnboardingManager">
        <hr class="dropdown-divider" />
      </li>

      <template v-if="isAgency">
        <li>
          <h5 class="dropdown-header fw-bold">Account Management:</h5>
        </li>
        <li>
          <span class="dropdown-item" @click="openHandsOnCommissionModal">
            <span>Hands-On Commission</span>
          </span>
        </li>
        <li>
          <router-link class="dropdown-item" @click="openAssignManagerModal" :to="{name: 'companies.list'}">
            <span>Replace Manager</span>
          </router-link>
        </li>
        <li>
          <router-link class="dropdown-item" @click="openChangeCommissionModal" :to="{name: 'companies.list'}">
            <span>Client Commission</span>
          </router-link>
        </li>
      </template>
      <template v-if="isAgency">
        <li>
          <hr class="dropdown-divider" />
        </li>
        <li>
          <h5 class="dropdown-header fw-bold">Financial:</h5>
        </li>
        <li>
          <span class="dropdown-item" @click="openOverdraftModal">Request Overdraft</span>
        </li>
      </template>
      <li v-if="!(canUseCreditLineList && (isLocal() || isDev())) && isAgency">
        <hr class="dropdown-divider" />
      </li>
      <template v-if="canUseCreditLineList && (isLocal() || isDev())">
        <li>
          <router-link class="dropdown-item" @click="openCreditLineModal" :to="{name: 'credit-line.list'}">
            <span>Credit line request</span>
          </router-link>
        </li>
        <li>
          <hr class="dropdown-divider" />
        </li>
      </template>
      <li v-if="canUseCreativeStudio">
        <h5 class="dropdown-header fw-bold">Creative Studio:</h5>
      </li>
      <li v-if="canCreateNewLandingTask">
        <a class="dropdown-item" :href="$router.resolve({name: 'tasks.new-landing'}).href">
          <span>Landing Page</span>
        </a>
      </li>

      <li v-if="canCreateCopyLandingTask">
        <a class="dropdown-item" :href="$router.resolve({name: 'tasks.landing-copy'}).href">
          <span>Clone Landing Page</span>
        </a>
      </li>
      <li v-if="canCreateABtestTask">
        <a class="dropdown-item" :href="$router.resolve({name: 'tasks.ab-test'}).href">
          <span>A/B Test</span>
        </a>
      </li>

      <template v-if="canCreateCreativeTask || canCreateShortTask">
        <li>
          <a class="dropdown-item" :href="$router.resolve({name: 'tasks.new-creatives-task'}).href">
            <span>Creative Request</span>
          </a>
        </li>
        <li>
          <a class="dropdown-item" :href="$router.resolve({name: 'tasks.new-short-task'}).href">
            <span>Short Task</span>
          </a>
        </li>
      </template>

      <template v-if="canCreateSMMTask">
        <li>
          <a class="dropdown-item" :href="$router.resolve({name: 'tasks.new-smm-task'}).href">
            <span>Social Media Content</span>
          </a>
        </li>
      </template>

      <li v-if="canUseCreativeStudio">
        <hr class="dropdown-divider" />
      </li>

      <li v-if="canCreateOtherTask">
        <a class="dropdown-item" :href="$router.resolve({name: 'tasks.other'}).href">
          <span>Other Request</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/custom-override-styles';

.btn-group:hover .btn-outline-primary {
  background: $primary;
  color: #fff;
}

.employee .dropdown-header {
  padding-left: 10px;
}

.employee .dropdown-item {
  padding-left: 20px;
}
</style>
