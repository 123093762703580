<script setup>
import {defineProps, defineEmits, ref, onMounted, defineExpose, computed} from 'vue'
import TheSelect from '@/components/ui/selects/TheSelect.vue'

const emit = defineEmits(['changed'])
const props = defineProps({
  activeRequestFields: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const defaultFormData = {
  ads_geo: '',
  url: '',
  business_type: '',
  business_name: '',
}
let formData = ref(structuredClone(defaultFormData))

const adsGeoOptions = [
  {label: 'United States', value: 'USA'},
  {label: 'Canada', value: 'Canada'},
  {label: 'New Zealand', value: 'New Zealand'},
]

const businessTypes = ['Online pharmacy', 'Pharmaceutical Manufacturer', 'Telehealth Provider']
const businessTypeOptions = computed(() => businessTypes.map(item => ({value: item, label: item})))

// Methods
onMounted(() => {
  init()
})

const init = () => {
  if (props.activeRequestFields) {
    formData.value = Object.assign(formData.value, props.activeRequestFields)
  }
}

const resetForm = () => {
  formData.value = structuredClone(defaultFormData)
}
const onChange = () => {
  emit('changed', {fields: formData.value})
}

defineExpose({resetForm})
</script>

<template>
  <div class="form-group">
    <label for="pharma-wl-ads_geo">Where will you run your ads? *</label>
    <div class="form-group">
      <the-select
        v-model="formData.ads_geo"
        @change="onChange"
        :options="adsGeoOptions"
        :disabled="disabled"
        id="pharma-wl-ads_geo"
      ></the-select>
    </div>
  </div>
  <div class="form-group">
    <label for="pharma-wl-url">URL *</label>
    <div class="input-group">
      <input
        v-model="formData.url"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="pharma-wl-url"
        type="url"
        required
      />
    </div>
    <small class="text-muted">(as certified by www.legitscript.com)</small>
  </div>
  <div class="form-group">
    <label for="pharma-wl-business_type">Business type *</label>
    <div class="form-group">
      <the-select
        v-model="formData.business_type"
        @change="onChange"
        :options="businessTypeOptions"
        :disabled="disabled"
        id="pharma-wl-business_type"
      ></the-select>
    </div>
  </div>
  <div class="form-group">
    <label for="pharma-wl-business_name">Business Name *</label>
    <div class="input-group">
      <input
        v-model="formData.business_name"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="pharma-wl-business_name"
        type="text"
        required
      />
    </div>
  </div>
</template>
