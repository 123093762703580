export function loadHotjar(hjid, hjsv) {
  return (function (window, document, baseURL, suffix) {
    // Initialize Hotjar if not already initialized
    window.hj =
      window.hj ||
      function () {
        return (window.hj.q = window.hj.q || []).push(arguments)
      }

    // Set Hotjar settings
    window._hjSettings = {hjid, hjsv}

    // Create and append the Hotjar script
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.async = true
    script.src = `${baseURL}${window._hjSettings.hjid}${suffix}${window._hjSettings.hjsv}`

    if (head) {
      head.appendChild(script)
    } else {
      console.error('Failed to append Hotjar script: element not found.')
    }
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}
