<script setup>
import {ref, onMounted, defineProps} from 'vue'
import {notify} from '@kyvg/vue3-notification'
import {useUserStore} from '@/stores/UserStore'
import {useAuthenticationStore} from '@/stores'
import {storeToRefs} from 'pinia'
import {findFirstAvailableRoute, isRouteActive} from '@/plugins/helpers'
import {accessRules} from '@/plugins/accessControl'
import {authenticationStore} from '@/main'

const auth = authenticationStore

const {isPoint2WebEmployee, isAgencyClient, hasAccessToProformaInvoices, requestsRouteName} = storeToRefs(
  useAuthenticationStore(),
)
const {getUserManager} = useUserStore()

const props = defineProps({
  fullHeight: {
    type: Boolean,
    default: true,
  },
})

// Refs
const managerLoading = ref(false)
const hasNoManager = ref(false)
const managersInfo = ref([
  {
    full_name: '',
    position: '',
    photo_path: null,
    manager_type: '',
    email: '',
    skype: '',
    telegram: '',
  },
])

// Methods
onMounted(() => {
  if (!isPoint2WebEmployee.value) {
    getManagerInfo()
  }
})

const getManagerInfo = async () => {
  managerLoading.value = true

  try {
    const managers = await getUserManager()

    if (!managers || managers.length === 0) {
      hasNoManager.value = true
    } else {
      managersInfo.value = managers
    }
  } catch (error) {
    hasNoManager.value = true

    notify({type: 'error', text: error.data.message})
  } finally {
    managerLoading.value = false
  }
}

const showManagerType = manager_type => {
  return manager_type === 'media_planner_social' ? 'Social' : 'Search'
}
</script>

<template>
  <div :class="['bg-white pb-5 h-100', props.fullHeight ? 'min-vh-100' : 'sidebar-height']">
    <div class="position-sticky top-0">
      <div class="p-4">
        <img src="@/assets/images/sidebar/pb_logo.webp" alt="pointboard" class="max-w-150-px d-block" />
      </div>
      <hr class="m-0" />

      <ul class="nav nav-pills flex-column pt-3" id="menu">
        <li class="nav-item">
          <router-link
            :class="['nav-link d-flex align-items-center px-4 py-3', isRouteActive('home').value ? 'active' : '']"
            :to="{name: 'home'}"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-home"></i>
            </span>
            <span class="ml-3 nav-text">Home Page</span>
          </router-link>
        </li>
        <li v-if="accessRules.hasAccessToDashboard(auth)" class="nav-item">
          <router-link
            :to="{name: 'dashboard'}"
            :class="['nav-link d-flex align-items-center px-4 py-3', isRouteActive('dashboard').value ? 'active' : '']"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-chart-line"></i>
            </span>

            <span class="ml-3 nav-text">Dashboard</span>
          </router-link>
        </li>
        <li v-if="accessRules.hasAccessToRequestsPage(auth)" class="nav-item">
          <router-link
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              isRouteActive('ad-account-requests').value ? 'active' : '',
            ]"
            :to="{name: requestsRouteName}"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="far fa-edit"></i>
            </span>

            <span class="ml-3 nav-text">Requests</span>
          </router-link>
        </li>
        <li v-if="accessRules.hasAccessToCreativeModerationList(auth)" class="nav-item">
          <router-link
            :to="{name: 'creatives.list'}"
            :class="[
              'nav-link d-flex align-items-center justify-content-between px-4 py-3',
              isRouteActive('creatives.list').value ? 'active' : '',
            ]"
            active-class="active"
          >
            <div class="d-flex align-items-center">
              <span class="nav-icon">
                <i class="fas fa-photo-video"></i>
              </span>
              <span class="ml-3 nav-text">Creatives Moderation</span>
            </div>
            <div v-if="false" class="small-badge-light-border ml-2">Beta</div>
          </router-link>
        </li>
        <li v-if="accessRules.hasAccessToCompaniesPage(auth)" class="nav-item">
          <router-link
            :to="{name: 'companies.list'}"
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              isRouteActive('companies.list').value ? 'active' : '',
            ]"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-sitemap"></i>
            </span>

            <span class="ml-3 nav-text">Companies</span>
          </router-link>
        </li>
        <li v-if="accessRules.hasAccessToAssetsPage(auth)" class="nav-item">
          <router-link
            :to="{name: 'assets.ad-accounts.list'}"
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              isRouteActive('assets.list').value ? 'active' : '',
            ]"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-address-card"></i>
            </span>

            <span class="ml-3 nav-text">Ad Accounts</span>
          </router-link>
        </li>

        <li v-if="accessRules.hasAccessToFinancePage(auth)" class="nav-item">
          <router-link
            :to="{
              name: isAgencyClient
                ? 'payments.daily_balance'
                : hasAccessToProformaInvoices
                ? 'proforma-invoices.list'
                : 'payments.list',
            }"
            :class="['nav-link d-flex align-items-center px-4 py-3', isRouteActive('finance').value ? 'active' : '']"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-coins"></i>
            </span>

            <span class="ml-3 nav-text">{{ isAgencyClient ? 'Financial Overview' : 'Finance' }}</span>
          </router-link>
        </li>
        <li v-if="accessRules.hasAccessToTracker(auth)" class="nav-item">
          <router-link
            :to="{name: 'campaigns.list'}"
            :class="['nav-link d-flex align-items-center px-4 py-3', isRouteActive('tracker').value ? 'active' : '']"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-ad"></i>
            </span>

            <span class="ml-3 nav-text">Tracker</span>
          </router-link>
        </li>
        <li v-if="accessRules.hasAccessToReports(auth)" class="nav-item">
          <router-link
            :to="{name: 'reports'}"
            active-class="active"
            :class="['nav-link d-flex align-items-center px-4 py-3', isRouteActive('reports').value ? 'active' : '']"
          >
            <span class="nav-icon">
              <i class="fas fa-chart-pie"></i>
            </span>

            <span class="ml-3 nav-text">Reports</span>
          </router-link>
        </li>
        <li v-if="accessRules.hasAccessToTools(auth)" class="nav-item">
          <router-link
            :to="{
              name: findFirstAvailableRoute('tools', auth).name,
            }"
            :class="['nav-link d-flex align-items-center px-4 py-3', isRouteActive('tools').value ? 'active' : '']"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-tools"></i>
            </span>

            <span class="ml-3 nav-text">Tools</span>
          </router-link>
        </li>
        <li v-if="accessRules.hasAccessToClientOnboarding(auth)" class="nav-item">
          <router-link
            :to="{name: 'onboarding'}"
            :class="['nav-link d-flex align-items-center px-4 py-3', isRouteActive('onboarding').value ? 'active' : '']"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-user-plus"></i>
            </span>

            <span class="ml-3 nav-text">Onboarding</span>
          </router-link>
        </li>
        <li v-if="accessRules.hasAccessToClickupPage(auth)" class="nav-item">
          <router-link
            :to="{name: 'clickup.list'}"
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              isRouteActive('clickup.list').value ? 'active' : '',
            ]"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-tasks"></i>
            </span>

            <span class="ml-3 nav-text">ClickUp</span>
          </router-link>
        </li>
        <template v-if="!isPoint2WebEmployee">
          <li class="nav-item">
            <a
              href="https://point2web.com/creative-studio/"
              rel="nofollow noopener noreferrer"
              target="_blank"
              class="nav-link d-flex align-items-center justify-content-between px-4 py-3"
            >
              <div class="d-flex align-items-center">
                <span class="nav-icon">
                  <i class="far fa-lightbulb"></i>
                </span>
                <span class="ml-3 nav-text">Creative Studio</span>
              </div>
              <i class="fas fa-external-link-alt pl-15-px"></i>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://point2web.com/performance-network/"
              rel="nofollow noopener noreferrer"
              target="_blank"
              class="nav-link d-flex align-items-center justify-content-between px-4 py-3"
            >
              <div class="d-flex align-items-center">
                <span class="nav-icon">
                  <i class="fas fa-tachometer-alt"></i>
                </span>

                <span class="ml-3 nav-text">Performance Network</span>
              </div>
              <i class="fas fa-external-link-alt pl-15-px"></i>
            </a>
          </li>
        </template>
      </ul>

      <div class="pt-3" v-if="!isPoint2WebEmployee && !hasNoManager">
        <hr class="m-0 pb-3" />
        <div v-for="(manager, index) in managersInfo" :key="index">
          <div class="px-4">
            <p class="fw-bold" v-if="index === 0">I'm here to help you:</p>
            <div class="d-flex gap-3 align-items-center">
              <div v-if="manager.photo_path" class="b-radius-50 w-80-px overflow-hidden border-2-grey">
                <img :src="manager.photo_path" :alt="manager.full_name" class="d-block max-w-100 w-100" />
              </div>
              <div>
                <p class="fw-bold mb-0">{{ manager.full_name }} ({{ showManagerType(manager.manager_type) }})</p>
                <p class="mb-3">{{ manager.position }}</p>
                <div class="d-flex gap-2 align-items-center">
                  <a
                    v-if="manager.skype"
                    :href="`skype:${manager.skype}?chat`"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    class="d-block b-radius-50 h-30-px w-30-px"
                  >
                    <img src="@/assets/images/sidebar/socials/skype.webp" alt="skype" class="d-block max-w-100 w-100" />
                  </a>
                  <a
                    v-if="manager.telegram"
                    :href="manager.telegram"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    class="d-block b-radius-50 h-30-px w-30-px"
                  >
                    <img
                      src="@/assets/images/sidebar/socials/telegram.webp"
                      alt="telegram"
                      class="d-block max-w-100 w-100"
                    />
                  </a>
                  <a
                    v-if="manager.email"
                    :href="`mailto:${manager.email}`"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    class="d-block b-radius-50 h-30-px w-30-px"
                  >
                    <img src="@/assets/images/sidebar/socials/email.webp" alt="email" class="d-block max-w-100 w-100" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr v-if="index + 1 < managersInfo.length" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.sidebar-height {
  min-height: calc(100vh - $impersonation-header-height);
}

.nav-link {
  color: #1d1c1c;
}

.nav-text {
  font-weight: 400;
  transition: all 0.1s ease;
}

.nav-icon {
  text-align: center;
  width: 25px;
  font-size: 20px;
  color: #b1b1b1;
}

.nav-pills .nav-link.active {
  background: #f2f4f8;
  color: #1d1c1c;
  position: relative;

  & .nav-text {
    font-weight: 700;
  }

  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 4px;
    background-color: #fb6639;
    border-radius: 25px;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    transition: opacity 0.3s;
  }

  & .nav-icon {
    color: #fb6639;
  }
}

.nav-item {
  min-width: 250px;
  position: relative;
  z-index: 1;

  &.active {
    background: #f2f4f8;
    color: #1d1c1c;
    position: relative;

    & .nav-text {
      font-weight: 700;
    }

    & .nav-icon {
      color: #fb6639;
    }

    & .btn-group {
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        display: block;
        height: 100%;
        width: 4px;
        background-color: #fb6639;
        border-radius: 25px;
        opacity: 1;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
      }
    }
  }

  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 4px;
    background-color: #fb6639;
    border-radius: 25px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    transition: opacity 0.3s;
  }

  &:hover {
    background: #f2f4f8;

    &:after {
      opacity: 1;
    }
  }

  &:hover .nav-icon {
    color: #fb6639;
  }

  &:hover .nav-text {
    font-weight: 700;
  }
}

.border-2-grey {
  border: 2px solid #464646;
}
</style>
