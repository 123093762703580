<script setup>
import {defineEmits, defineProps, ref, computed, onMounted, defineAsyncComponent} from 'vue'
import {notify} from '@kyvg/vue3-notification'
import BaseForm from '@/components/base/BaseForm.vue'
import ApiErrors from '@/components/ui/ApiErrors.vue'
import TheSelectAdAccounts from '@/components/ui/selects/TheSelectAdAccounts.vue'
import {useAdAccountTopUpRequestStore} from '@/views/ad-account-requests/top-up/AdAccountTopUpRequestStore'
import {storeToRefs} from 'pinia'
import {useAuthenticationStore} from '@/stores'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import TheModal from '@/components/ui/TheModal.vue'
import AlertDanger from '@/components/ui/alerts/AlertDanger.vue'
import TheSelect from '@/components/ui/selects/TheSelect.vue'
import {useCompanyStore} from '@/views/companies/CompanyStore'

const InvoiceModal = defineAsyncComponent(() => import('@/views/finance/components/InvoiceModal.vue'))

const {createAdAccountTopUpRequest} = useAdAccountTopUpRequestStore()
const {userCompanyHash, canUseTopUpAsap, isCoreTeam, canUseInvoices} = storeToRefs(useAuthenticationStore())
const {companiesOptions} = storeToRefs(useCompanyStore())

// Emits & props
const props = defineProps({
  id: {
    type: String,
    default: null,
  },
})
let emit = defineEmits(['created', 'updated', 'hidden'])

// Refs
const modalId = props.id ?? 'ad-account-top-up-request-modal'
const theModal = ref()
const showInvoiceModal = ref(false)
const defaultFormData = {
  entity_id: null,
  entity_type: null,
  amount: null,
  is_asap: false,
  is_withdraw: false,
}
const formData = ref([structuredClone(defaultFormData)])
const companyHash = ref(userCompanyHash.value)
const error = ref(null)
const loading = ref(false)

// Computed
const canSubmit = computed(() => {
  return formData.value.every(
    item => item.entity_id && item.amount !== null && item.amount !== '' && item.is_disabled === false,
  )
})
const isCompanyBalanceError = computed(() => error.value?.errors?.company_balances?.length > 0)

// Methods
onMounted(() => {
  openModal()
})

const openModal = () => {
  formData.value = [structuredClone(defaultFormData)]
  theModal.value.handleOpenModal()
}

const closeModal = () => {
  theModal.value.handleCloseModal()
}

const handleCreate = async () => {
  loading.value = true

  try {
    await createAdAccountTopUpRequest(getRequestParams())
  } catch (responseError) {
    return (error.value = responseError.data)
  } finally {
    loading.value = false
  }

  emit('created')
  notify({type: 'success', text: 'Top-up request submitted'})
  closeModal()
}

const getRequestParams = () => {
  return structuredClone(formData.value).map(item => {
    delete item.is_disabled
    return {
      ...item,
      amount: item.is_withdraw ? Math.abs(item.amount) * -1 : item.amount,
    }
  })
}

const onAdAccountChanged = (adAccount, index) => {
  formData.value[index].entity_type = adAccount?.entity_type
}

const addNewItem = () => {
  formData.value.push(structuredClone(defaultFormData))
}

const removeItem = index => {
  formData.value.splice(index, 1)
}

const setIsOptionDisabled = (option, index) => {
  formData.value[index].is_disabled = option.is_disabled
}
const onCompanyChange = () => {
  formData.value = [structuredClone(defaultFormData)]

  formData.value.forEach(item => {
    item.entity_id = null
    delete item.is_disabled
  })
}

const openInvoiceModal = () => {
  showInvoiceModal.value = true
}

const emitHidden = () => {
  emit('hidden')
}
</script>

<template>
  <Teleport to="body">
    <invoice-modal
      v-if="showInvoiceModal"
      :parent-modal-id="modalId"
      @hidden="showInvoiceModal = false"
      id="top-up-invoice-modal"
    />
  </Teleport>
  <TheModal ref="theModal" @hidden="emitHidden" class="modal-lg" :id="modalId" title="Top-Up Request">
    <base-form @on-submitted="handleCreate">
      <div class="modal-body">
        <div class="form-group row">
          <div v-if="isCoreTeam" class="col-6">
            <label>Company *</label>
            <the-select
              v-model="companyHash"
              :options="companiesOptions"
              :disabled="loading"
              :allow_clear="false"
              @change="onCompanyChange"
              placeholder=""
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>Ad account *</label>
          </div>
          <div class="col-3">
            <label>Sum *</label>
          </div>
          <div class="col-auto"></div>
          <div class="col-auto"></div>
        </div>
        <div v-for="(item, index) in formData" :key="index" class="form-group row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-6">
                <the-select-ad-accounts
                  v-model="item.entity_id"
                  :list-by-search="!!userCompanyHash"
                  :payload="{with_can_has_top_up: 1, company_hash: companyHash}"
                  @changed="onAdAccountChanged($event, index)"
                  @select-option="setIsOptionDisabled($event, index)"
                  :disabled="(isCoreTeam && !companyHash) || loading"
                  required
                ></the-select-ad-accounts>
              </div>

              <div class="col-3">
                <div class="input-group">
                  <span class="input-group-text">$</span>
                  <input
                    v-model="item.amount"
                    :class="['form-control', isCompanyBalanceError ? 'is-invalid' : '']"
                    type="number"
                    step="0.01"
                    :disabled="loading"
                    required
                  />
                </div>
              </div>

              <div class="col-auto ps-0">
                <base-checkbox
                  v-model="item.is_withdraw"
                  title="Withdraw"
                  :id="`top-up-${modalId}-${index + 1}-is_withdraw`"
                  :disabled="loading"
                ></base-checkbox>
                <base-checkbox
                  v-if="canUseTopUpAsap"
                  v-model="item.is_asap"
                  title="ASAP"
                  :id="`top-up-${modalId}-${index + 1}-asap`"
                  :disabled="loading"
                ></base-checkbox>
              </div>

              <div class="col-auto ps-0">
                <button
                  v-show="formData?.length > 1"
                  class="btn"
                  type="button"
                  :disabled="loading"
                  @click="removeItem(index)"
                >
                  <span class="fa fa-trash text-muted"></span>
                </button>
              </div>
            </div>

            <alert-danger v-if="item.is_disabled" class="mt-3">
              Unable to top up the advertising account. Possible reasons:
              <ul class="my-1">
                <li class="fw-bold">The account may be blocked.</li>
                <li class="fw-bold">A credit line is activated.</li>
              </ul>
              If the problem persists, please contact your manager for further information.
            </alert-danger>
            <div class="row mt-2">
              <div class="col"></div>
            </div>
          </div>
        </div>

        <alert-danger v-if="isCompanyBalanceError" class="my-3">
          <div class="fw-bold">Oops! You’re exceeding the allowed top-up limit.</div>
          <div>You can only top-up 90% of your available funds – the rest covers platform fees.</div>
          <div class="text-dark">
            Enter a lower amount to proceed<span v-if="canUseInvoices">
              or
              <span class="link-info link-default-decoration fw-bold pointer" @click="openInvoiceModal"
                >Top-Up Balance</span
              >
              now</span
            >.
          </div>
        </alert-danger>

        <button @click="addNewItem" class="btn btn-secondary btn-sm" :disabled="loading" type="button">Add</button>

        <api-errors v-if="error && !isCompanyBalanceError" :error="error" class="mt-3"></api-errors>
      </div>
      <div class="modal-footer justify-content-between">
        <button class="btn btn-primary" :disabled="loading || !canSubmit">Submit</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </base-form>
  </TheModal>
</template>
