<script setup>
import {defineEmits, ref, defineProps, onMounted, defineExpose} from 'vue'
import TheSelectMultiple from '@/components/ui/selects/TheSelectMultiple.vue'
import {openUrl} from '@/plugins/helpers'

// Props & emits
let emit = defineEmits(['file-updated', 'delete-file', 'changed'])

const props = defineProps({
  activeRequestFields: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

let requestFormData = new FormData()
const uploadedLicenseFileName = ref('')
const uploadedGameScreenshotFileName = ref('')
const uploadedRedirectScreenshotFileName = ref('')
let protectiveMeasureOptions = [
  {
    value: 'Geo-blocking of the URL(s)',
    label: 'Geo-blocking of the URL(s)',
  },
  {
    value: 'Age-gating of the URL(s)',
    label: 'Age-gating of the URL(s)',
  },
  {
    value: 'Address verification software or processes',
    label: 'Address verification software or processes',
  },
  {
    value: 'KYC checks (incl. ID, age, address, bank detail checks)',
    label: 'KYC checks (incl. ID, age, address, bank detail checks)',
  },
  {
    value: 'Registration requires a local mobile number',
    label: 'Registration requires a local mobile number',
  },
  {
    value: 'Registration requires the national tax identification number',
    label: 'Registration requires the national tax identification number',
  },
]

const defaultFormData = {
  services_urls: '',
  legal_company_name: '',
  preland_url: '',
  geo: '',
  protective_measures: [],
}
let formData = ref(structuredClone(defaultFormData))

// Methods
onMounted(() => {
  init()
})

const init = () => {
  if (props.activeRequestFields) {
    formData.value = Object.assign(formData.value, props.activeRequestFields)
  }
}
const resetForm = () => {
  formData.value = structuredClone(defaultFormData)
}
const onFileUploaded = (event = null, file_type) => {
  if (!event.target.files[0] || requestFormData.get(`fields[${file_type}]`)) {
    return
  }

  requestFormData.set(`fields[${file_type}]`, event.target.files[0], event.target.files[0].name)

  switch (file_type) {
    case 'license':
      uploadedLicenseFileName.value = event.target.files[0].name
      break

    case 'games_screenshot':
      uploadedGameScreenshotFileName.value = event.target.files[0].name
      break

    case 'redirect_screenshot':
      uploadedRedirectScreenshotFileName.value = event.target.files[0].name
      break
  }

  updateFormPayload()
}

const deleteUploadedFile = item => {
  requestFormData.delete(`fields[${item}]`)

  switch (item) {
    case 'license':
      uploadedLicenseFileName.value = ''
      break

    case 'games_screenshot':
      uploadedGameScreenshotFileName.value = ''
      break

    case 'redirect_screenshot':
      uploadedRedirectScreenshotFileName.value = ''
      break
  }

  emit('delete-file', item)
}

const updateFormPayload = () => {
  emit('file-updated', requestFormData)
}

const onChange = () => {
  emit('changed', {fields: formData.value})
}

defineExpose({resetForm})
</script>

<template>
  <div class="form-group">
    <label for="gambling-wl-legal_company_name">Advertiser's company name *</label>
    <input
      v-model="formData.legal_company_name"
      @change="onChange"
      :disabled="disabled"
      class="form-control"
      id="gambling-wl-legal_company_name"
      required
    />
    <small class="text-muted">Legal entity name of the business</small>
  </div>
  <div class="form-group">
    <label for="gambling-wl-services_urls">URL of the gambling service(s) *</label>
    <textarea
      v-model="formData.services_urls"
      @change="onChange"
      :disabled="disabled"
      class="form-control"
      id="gambling-wl-services_urls"
      required
      rows="1"
    />
    <small class="text-muted">Up to 5</small>
  </div>
  <div class="form-group">
    <label for="gambling-wl-preland-link">Will you use a preland?</label>
    <input
      v-model="formData.preland_url"
      @change="onChange"
      :disabled="disabled"
      class="form-control"
      id="gambling-wl-preland-link"
      type="text"
    />
    <small class="text-muted">If yes, please provide the link</small>
  </div>
  <div class="form-group">
    <label for="gambling-wl-license-file-upload"
      >License / Legal memo proving the legality of the product without need for a license in each of the targeted
      territories.</label
    >
    <template v-if="formData?.license_url">
      <div>
        <span @click="openUrl(formData?.license_url)" class="full-width btn btn-outline-success">Open</span>
      </div>
    </template>
    <template v-else>
      <div>
        <template v-if="!uploadedLicenseFileName">
          <label for="gambling-wl-license-file-upload" class="full-width btn btn-secondary">
            <i class="fa fa-upload"></i> Add file
          </label>

          <input
            class="d-none"
            @change="onFileUploaded($event, 'license')"
            id="gambling-wl-license-file-upload"
            type="file"
            :disabled="disabled"
          />
        </template>
        <template v-else>
          <span class="full-width btn btn-outline-success"
            >{{ uploadedLicenseFileName }}
            <button type="button" class="btn-close circle-btn" @click="deleteUploadedFile('license')" />
          </span>
        </template>
      </div>
    </template>
  </div>

  <div class="form-group">
    <label for="gambling-wl-protective_measures">Protective measures *</label>
    <template v-if="disabled">
      <div class="alert alert-primary py-2">
        <span v-for="(item, index) in formData.protective_measures" :key="index"> - {{ item }} <br /> </span>
      </div>
    </template>
    <template v-else>
      <div class="col">
        <the-select-multiple
          :options="protectiveMeasureOptions"
          v-model="formData.protective_measures"
          @change="onChange"
          :disabled="disabled"
          mode="multiple"
          input-placeholder="Protective measures"
          id="gambling-wl-protective_measures"
          required
        ></the-select-multiple>
        <small class="text-muted">Please select as many protective measures as apply</small>
      </div>
    </template>
  </div>
  <div class="form-group">
    <label for="gambling-wl-geo">Geo selection *</label>
    <input
      v-model="formData.geo"
      @change="onChange"
      :disabled="disabled"
      class="form-control"
      id="gambling-wl-geo"
      required
    />
    <small class="text-muted">
      Please indicate the territory/sub-territories which you would like to target). If it is US, please indicate each
      state in what you are interested separately.
    </small>
  </div>
  <div class="form-group">
    <label for="gambling-wl-screenshots-file-upload"
      >Screenshots of all games available on the URL (if you have)
    </label>
    <template v-if="formData?.games_screenshot_url">
      <div>
        <span @click="openUrl(formData?.games_screenshot_url)" class="full-width btn btn-outline-success">Open</span>
      </div>
    </template>
    <template v-else>
      <div>
        <template v-if="!uploadedGameScreenshotFileName">
          <template v-if="disabled">
            <div class="alert alert-primary text-center py-2">No file</div>
          </template>
          <template v-else>
            <label for="gambling-wl-screenshots-file-upload" class="full-width btn btn-secondary">
              <i class="fa fa-upload"></i> Add file
            </label>
            <input
              class="d-none"
              @change="onFileUploaded($event, 'games_screenshot')"
              id="gambling-wl-screenshots-file-upload"
              type="file"
              :disabled="disabled"
            />
          </template>
        </template>
        <template v-else>
          <span class="full-width btn btn-outline-success"
            >{{ uploadedGameScreenshotFileName }}
            <button type="button" class="btn-close circle-btn" @click="deleteUploadedFile('games_screenshot')" />
          </span>
        </template>
      </div>
    </template>
  </div>
  <div class="form-group">
    <label for="gambling-wl-redirect_screenshot-file-upload"
      >If the URL(s) automatically redirect to a different website, please add the following screenshots (confirmation
      to which URL it redirects to)
    </label>
    <template v-if="formData?.redirect_screenshot_url">
      <div>
        <span @click="openUrl(formData?.redirect_screenshot_url)" class="full-width btn btn-outline-success"
          >Open
        </span>
      </div>
    </template>
    <template v-else>
      <div>
        <template v-if="!uploadedRedirectScreenshotFileName">
          <template v-if="disabled">
            <div class="alert alert-primary text-center py-2">No file</div>
          </template>
          <template v-else>
            <label for="gambling-wl-redirect_screenshot-file-upload" class="full-width btn btn-secondary">
              <i class="fa fa-upload"></i> Add file
            </label>

            <input
              class="d-none"
              @change="onFileUploaded($event, 'redirect_screenshot')"
              id="gambling-wl-redirect_screenshot-file-upload"
              type="file"
              :disabled="disabled"
            />
          </template>
        </template>
        <template v-else>
          <span class="full-width btn btn-outline-success"
            >{{ uploadedRedirectScreenshotFileName }}
            <button type="button" class="btn-close circle-btn" @click="deleteUploadedFile('redirect_screenshot')" />
          </span>
        </template>
      </div>
    </template>
  </div>
</template>
