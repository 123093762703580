import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useCompanyStore = defineStore({
  id: 'companyStore',
  state: () => ({
    companies: [],
    companiesLoading: false,
    error: null,
    showCompanyModal: false,
    companyToEdit: null,
    companyTab: null,
    types: ['Solo affiliate', 'Media Buying Team', 'Direct Advertiser / Product Owner', 'Agency'],
  }),
  getters: {
    companiesOptions: state => {
      return state.companies.map(({hash, title}) => ({value: hash, label: title}))
    },
    companyTypesOptions: state => {
      return state.types.map(item => ({value: item, label: item}))
    },
    externalCompaniesOptions: state => {
      return state.companies
        .filter(n => n.is_internal === 0)
        .map(({hash, title}) => ({
          value: hash,
          label: title,
        }))
    },
    companiesWithEmptyOptions: state => {
      let result = state.companies.map(({hash, title}) => ({value: hash, label: title}))

      result.unshift({value: 0, label: 'No company'})

      return result
    },
    internalCompaniesOptions: state => {
      return state.companies
        .filter(n => n.is_internal === 1)
        .map(({hash, title, is_sf}) => ({
          value: hash,
          label: title,
          is_sf: !!is_sf,
        }))
    },
    verticals: () => {
      return [
        'App - Antivirus',
        'App - Utility',
        'App - VPN',
        'Comics',
        'Content Arbitrage',
        'Crypto',
        'Dating - AI',
        'Dating - Adult',
        'Dating - Mainstream',
        'Debt Consolidation',
        'Debt Relief',
        'E-commerce',
        'Education',
        'Employment',
        'Finance',
        'Forex',
        'Gaming',
        'Health & Beauty',
        'Home Services',
        'Horoscopes',
        'Insurance - Auto',
        'Insurance - Health',
        'Insurance - Home',
        'Insurance - Life',
        'Loans - PayDay',
        'Loans - Personal',
        'Medicare',
        'Nutra',
        'Real Estate',
        'Search Arbitrage',
        'SOI Sweepstakes',
        'Social Casino',
        'Streaming services',
        'Trading',
        'Travel',
        'Other',
      ]
    },
  },
  actions: {
    async getCompanies(filters = {}, changeCompaniesGlobally = true) {
      this.error = null
      this.companiesLoading = true

      try {
        const {data} = await axios.get('companies', {params: filters})

        if (changeCompaniesGlobally) {
          this.companies = data
        }

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.companiesLoading = false
      }
    },
    async createCompany(payload) {
      const {data} = await axios.post('companies', payload)

      return data
    },
    async updateCompany(payload, hash) {
      await axios.patch(`companies/${hash}`, payload)

      return true
    },

    async getCompanyTags() {
      const {data} = await axios.get('company-tags')

      return data
    },

    async getCompanySpendStats(hash) {
      const {data} = await axios.get(`/companies/${hash}/spend-stats`)

      return data
    },

    async getCompanyToEdit(company_hash) {
      try {
        let {data} = await axios.get(`companies/${company_hash}`, {
          params: {
            with: [
              'sale_manager',
              'media_planner',
              'media_planner_2',
              'csm',
              'tags',
              'verticals',
              'first_user',
              'meta',
            ],
            with_count: [
              'users',
              'quick_books_customers',
              'documents',
              'invoices',
              'payments',
              'overdraft_requests',
              'statuses_history',
            ],
          },
        })

        data.balances_diff = Math.abs(data.qb_balance) - data.balance

        this.companyToEdit = data

        return data
      } catch (error) {
        //
      }
    },

    async getStatusesHistory(companyHash) {
      const {data} = await axios.get(`companies/${companyHash}/status-history`)

      return data
    },

    async openCompanyModal(company_hash, tab) {
      if (company_hash) {
        await this.getCompanyToEdit(company_hash)
      } else {
        this.companyToEdit = null
      }

      this.showCompanyModal = true
      this.companyTab = tab
    },

    // Counters
    incrementQuickBooksCustomerCount() {
      this.companyToEdit.quick_books_customers_count += 1
    },

    decreaseQuickBooksCustomerCount() {
      this.companyToEdit.quick_books_customers_count -= 1
    },

    incrementUserCount() {
      this.companyToEdit.users_count += 1
    },

    incrementInvoicesCount() {
      this.companyToEdit.invoices_count += 1
    },

    incrementPaymentsCount() {
      this.companyToEdit.payments_count += 1
    },

    incrementDocumentsCount() {
      this.companyToEdit.documents_count += 1
    },
  },
})
